/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.getrevue.co/profile/outsideris"
  }, "Outsider's Dev Story 뉴스레터 - Revue")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clubhouse.com/"
  }, "Clubhouse: Here you are")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://create.twitter.com/en/products/twitter-spaces"
  }, "Twitter Spaces")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.neom.com/en-us/regions/theline"
  }, "THE LINE")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=0kz5vEqdaSc"
  }, "NEOM | What is THE LINE? - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/annonce/17444736"
  }, "모든 회원의 비밀번호를 초기화하였습니다. : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fidoalliance.org/"
  }, "FIDO Alliance - Open Authentication Standards More Secure than Passwords")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/"
  }, "1Password")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
